import googlePlaces from 'google-maps-reviews';

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import SwiperCore, {Navigation, Pagination} from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);

window.swiper = Swiper;
window.googlePlaces = googlePlaces;

document.addEventListener("DOMContentLoaded", function (event) {
    //Find a placeID via https://developers.google.com/places/place-id
    googlePlaces("google-reviews", {
        placeId: window.prestartGid,
        header: "", // html/text over Reviews
        footer: '', // html/text under Reviews block
        max_rows: 10, // max rows of reviews to be displayed
        min_rating: 4, // minimum rating of reviews to be displayed
        months: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
        text_break_length: "50", // length before a review box is set to max width
        //moreReviewsButtonUrl: '', // url to Google Place reviews popup
        //moreReviewsButtonLabel: 'Show More Reviews',
        show_date: true, // renders the date of the review before the review itself
        shorten_names: false, // example: "Max Mustermann" -> "Max M.""
        replace_anonymous: false, // do not replace anonymous author_name from JSON
        anonymous_name: "A Google User", // Google's default value depending on language used (en: "A Google User")
        anonymous_name_replacement: "User chose to remain anonymous", // replacement for default (never shortens)
        write_review_button_url: '', // url to Google Place write review popup
        write_review_button_label: 'Write New Review',
        show_profile_picture: true
    });


    var targetNode = document.getElementById('google-reviews');
    var config = {attributes: true, childList: true, subtree: true};
    var callback = function (mutationsList, observer) {
        for (var mutation of mutationsList) {
            if (mutation.type == 'childList') {
                document.querySelectorAll(".review-item-long").forEach(e => e.classList.add("swiper-slide"));
                document.querySelectorAll(".review-item").forEach(e => e.classList.add("swiper-slide"));
                if (!document.querySelector(".swiper-container").classList.contains("swiper-container-initialized")) {
                    const swiper = new Swiper(".swiper-container", {
                        // Optional parameters
                        direction: 'horizontal',
                        loop: true,
                        slidesPerView: 1,

                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },

                        // autoplay: {
                        //     delay: 5000,
                        // },
                    });
                    observer.disconnect();
                }
            }
        }
    };
    var observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

});
